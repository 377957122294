import React, { createContext, useEffect, useState } from "react";
import api from "../../api";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [ user, setUser ] = useState({});
    useEffect(() => {
        var tokenReady = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
        if(tokenReady) {
            setIsAuthenticated(true)
            findUser()
        }
    }, [])
    const login = () => {
        setIsAuthenticated(true);
        findUser()
    };

    const logout = () => {
        setIsAuthenticated(false);
        
    };
    const findUser = () => {
        api.get("session").then(res => {
            let usuario = res?.data;
            setUser(usuario)
        })
    }
    return (
        <UserContext.Provider value={{ user, isAuthenticated, login, logout, findUser }}>
            {children}
        </UserContext.Provider>
    );
};