import React from 'react'
import { Menu, MenuItem } from '@mui/material';
import { Refresh } from '@mui/icons-material';

export default function MenuContext({ anchorEl, handleClose, handleRefreshData }) {

    const handleRefresh = () => {
        handleRefreshData();
        handleClose();
    };
    return (
        <>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl !== null ? {
                        top: anchorEl.mouseY,
                        left: anchorEl.mouseX,
                    } : undefined
                }
            >
                <MenuItem onClick={handleRefresh}>
                    <Refresh />
                    Atualizar dados
                </MenuItem>
            </Menu>
        </>
    );
}
