import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Button, Grid, Typography, TextField, TableSortLabel } from '@mui/material';
import api from '../../api'
import { Box, Container } from '@mui/system';
import { filterTable, getPropertyByDotNotation } from '../../Utils/'
import ManageBI from './components/ManageBI';
import MenuContext from './components/MenuContext';
import { Delete, InsertChart, ModeEdit, PreviewOutlined } from '@mui/icons-material';
import { useConfirm } from 'components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'components/UserProvider';
const columns = [
    { id: 'id', label: 'RelId', minWidth: 0 },
    { id: 'nome', label: 'Nome', minWidth: 0 },
    { id: 'descricao', label: 'Descricao', minWidth: 0, maxWidth: 800 },
];

const PowerBI = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = React.useState([]);
    const [filter, setFilter] = React.useState("");
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentEditing, setCurrentEditing] = useState(null)
    const [manageBI, setManageBI] = useState(false);
    const confirmDialog = useConfirm()
    const { user } = React.useContext(UserContext)
    const navigate = useNavigate();
    const handleView = (event) => {
        var id = event.target.dataset.id;
        if(id == null) return;
        navigate(`/powerbi/${id}`)
    }
    const handleEdit = async (event) => {
        var id = event.target.dataset.id;
        if (id == null) return;
        var rel = rows.filter(f => f['id'] == id)[0]
        if(rel == null) return;
        setCurrentEditing(rel)
        handleOpenManageBI();
    }
    const handleDelete = async (event) => {
        var id = event.target.dataset.id;
        if(id == null) return;
        var rel = rows.filter(f => f['id'] == id)[0]
        if (rel == null) return;
        confirmDialog({
            message: `Você realmente deseja deletar o relatório: ${rel.nome}`,
            title: 'Deletar Relatório',
            onConfirm: async () => {
                const res = await api.delete(`powerbi/${id}`);
                if (res.type === 'success') handleRefreshData();
            },
        })
    }
    
    const handleOpenManageBI = () => {
        setManageBI(true)
    }
    const handleCloseManageBI = () => {
        setManageBI(false)
    }
    const handleAddPowerBI = (event) => {
        setCurrentEditing(null);
        handleOpenManageBI();
    }
    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(
            anchorEl === null
                ? {
                    mouseX: event.clientX,
                    mouseY: event.clientY,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleCloseMenuContext = () => {
        setAnchorEl(null);
    };
    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(columnId);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleFilter = (event) => {
        setFilter(event.target.value)
    }
    const handleRefreshData = React.useCallback(async () => {
        var res = await api.get("powerbi")
        if (res['type'] === 'success')
            setRows(res['data']);
    },[])

    React.useEffect(() => {
        handleRefreshData();
    }, [handleRefreshData])
    return (
        <Container maxWidth="xl" onContextMenu={handleContextMenu}>
            <ManageBI open={manageBI} onRefreshData={handleRefreshData} onClose={handleCloseManageBI} currentEditing={currentEditing} />
            <MenuContext anchorEl={anchorEl} onClose={handleCloseMenuContext} onRefreshData={handleRefreshData} onAddPowerBI={handleAddPowerBI}/>
            <Grid container direction="column" gap={3} marginTop={3}>
                <Grid item container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" align="center">
                            Relatórios
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{width: '100%'}}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: "calc(100vh - 240px)", width: '100%' }} >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead >
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{ minWidth: column.minWidth, background: "#444444", maxWidth: column['maxWidth'] }}
                                                hidden={column.hidden}
                                                sortDirection={orderBy === column.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={() => handleSort(column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ background: "#444444", minWidth: 220 }} colSpan={2}>
                                            <TextField fullWidth label="Procurar..." value={filter} onChange={handleFilter} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filterTable(rows, filter, columns)
                                        .sort((a, b) => {
                                            const aVal = getPropertyByDotNotation(a, orderBy);
                                            const bVal = getPropertyByDotNotation(b, orderBy);
                                            if (order === 'asc') {
                                                return aVal > bVal ? 1 : -1;
                                            } else {
                                                return aVal < bVal ? 1 : -1;
                                            }
                                        })
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {columns.map((column) => {
                                                        const value = getPropertyByDotNotation(row, column.id);
                                                        return (
                                                            <TableCell 
                                                                key={column.id} 
                                                                align={column.align} 
                                                                hidden={column.hidden}
                                                                sx={{ 
                                                                    minWidth: column.minWidth, 
                                                                    maxWidth: column['maxWidth'],
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'hidden'
                                                                }}
                                                            >
                                                                {column.format ? column.format(value) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    <TableCell colSpan={2}>
                                                        <Box sx={{width: '100%', gap: 3, display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                                                            <Button onClick={handleView} variant='outlined' color='primary' data-id={row['id']} endIcon={<InsertChart />}>Visualizar</Button>
                                                            {user['admin'] == true &&
                                                                <React.Fragment>
                                                                    <Button onClick={handleEdit} variant='outlined' color='secondary' data-id={row['id']} endIcon={<ModeEdit />}>Editar</Button>
                                                                    <Button onClick={handleDelete} variant='outlined' color='error' data-id={row['id']} endIcon={<Delete />}>Deletar</Button>
                                                                </React.Fragment>
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>

                                            );
                                        })}
                                </TableBody>
                            </Table>
                            
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Linhas por página:"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>

    );
};

export default PowerBI;