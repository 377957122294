import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from 'components/UserProvider';
import NavMenu from 'components/NavMenu'
export default function PrivateRoute () {
    const { isAuthenticated } = useContext(UserContext); // determine if authorized, from context or however you're doing it
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(() => {
        setLoaded(true)
    }, [])
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? <React.Fragment> <NavMenu /> <Outlet /></React.Fragment> : loaded ?  <Navigate to="/login" replace={true} /> : <></>
}