import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Button, Grid, Typography, TextField, TableSortLabel } from '@mui/material';
import { TableStic } from '@mui/material';
import api from '../../api'
import AddIcon from '@mui/icons-material/Add';
import { Container } from '@mui/system';
import { filterTable, getPropertyByDotNotation } from '../../Utils/'
import MenuContext from './components/MenuContext';
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
const columns = [
    { id: 'tarefa.id', label: 'TarefaId', minWidth: 0 },
    { id: 'tarefa.nome', label: 'Tarefa', minWidth: 0 },
    { id: 'tarefa.workFlowId', label: 'WorkFlowId', minWidth: 0 },
    { id: 'mensagem', label: 'Descrição', minWidth: 0 },
    { id: 'sucesso', label: 'Sucesso', minWidth: 100, format: (value) => value ? "Sim" : "Não" },
    {
        id: 'dataExecucao', label: 'Início de Execução', minWidth: 170, align: 'left', format: (value) => new Date(value).toLocaleDateString('pt-BR', options) },
    {
        id: 'dataFimExecucao', label: 'Fim de Execução', minWidth: 170, align: 'left', format: (value) => value != null && new Date(value).toLocaleDateString('pt-BR', options) || "" },
    ];

const Home = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = React.useState([]);
    const [filter, setFilter] = React.useState("");
    const [orderBy, setOrderBy] = useState('tarefa.nome');
    const [order, setOrder] = useState('asc');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(
            anchorEl === null
                ? {
                    mouseX: event.clientX,
                    mouseY: event.clientY,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleCloseMenuContext = () => {
        setAnchorEl(null);
    };
    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(columnId);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleFilter = (event) => {
        setFilter(event.target.value)
    }
    const handleRefreshData = async () => {
        let { data } = await api.get("tarefalog")
        setRows(data);
    }
    React.useEffect(() => {
        handleRefreshData();
    }, [])
    return (
        <Container maxWidth="xl">
            <Grid container direction="column" gap={3} marginTop={3}>
                <Grid item container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" align="center">
                            Logs de Execução
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: "calc(100vh - 240px)" }}>
                            <Table stickyHeader aria-label="sticky table" onContextMenu={handleContextMenu}>
                                <TableHead >
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell 
                                                key={column.id} 
                                                align={column.align} 
                                                style={{ minWidth: column.minWidth, background: "#444444" }} 
                                                hidden={column.hidden}
                                                sortDirection={orderBy === column.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={() => handleSort(column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ background: "#444444", minWidth: 220 }} colSpan={2}>
                                            <TextField fullWidth label="Procurar..." value={filter} onChange={handleFilter} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filterTable(rows, filter, columns)
                                        .sort((a, b) => {
                                        const aVal = getPropertyByDotNotation(a, orderBy);
                                        const bVal = getPropertyByDotNotation(b, orderBy);
                                        if (order === 'asc') {
                                            return aVal > bVal ? 1 : -1;
                                        } else {
                                            return aVal < bVal ? 1 : -1;
                                        }
                                        })
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {columns.map((column) => {
                                                    const value = getPropertyByDotNotation(row, column.id);
                                                    return (
                                                        <TableCell key={column.id} align={column.align} hidden={column.hidden}>
                                                            {column.format ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell colSpan={2}></TableCell>
                                            </TableRow>
                                            
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <MenuContext anchorEl={anchorEl} handleClose={handleCloseMenuContext} handleRefreshData={handleRefreshData} />
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Linhas por página:"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>

    );
};

export default Home;