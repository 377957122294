import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, TextField, Switch, FormControlLabel, FormGroup} from '@mui/material';
import { UserContext } from '../../UserProvider';
import ProfileAvatar from './ProfileAvatar';
import api from '../../../api';
const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Profile({ open, handleClose }) {
    const { findUser, user } = React.useContext(UserContext)
    const [errorPassword, setErrorPassword] = React.useState(false)
    const [profile, setProfile] = React.useState(user)
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(profile)
        if(profile.checksenha && profile.senha && profile.senha !== profile.checksenha ) return;
        let { type } = await api.put("profile", profile);
        console.log(type)
        if(type === "success") {
            findUser();
            handleClose();
        }
            
    }
    const handleChangeProfile = (event) => {
        setProfile(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
        if(event.target.name === "checksenha") {
            if (profile?.senha === event.target.value)
                setErrorPassword(false)
            else 
                setErrorPassword(true)
        }
    }
    React.useEffect(() => {
        setProfile(user)
    }, [user])
    const handlePodioCredentialChange = (event) => {
        setProfile(prevState => ({ ...prevState, [event.target.name]: event.target.checked }))
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography id="transition-modal-title" variant="h6" textAlign={"center"} component="h2">
                                    Editar Perfil
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ProfileAvatar value={profile?.foto} onChange={handleChangeProfile}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Nome" fullWidth name="nome" onChange={handleChangeProfile} value={profile?.nome} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Email" fullWidth name="email" type="email" onChange={handleChangeProfile} value={profile?.email} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Login" fullWidth name="username" onChange={handleChangeProfile} value={profile?.username} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Senha" helperText={"Deixe em branco caso não queira alterar"} fullWidth name="senha" type={"password"} onChange={handleChangeProfile} value={profile?.senha || ""} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Confirmação de senha" error={errorPassword} helperText={errorPassword && "As senhas não são iguais"} fullWidth name="checksenha" type={"password"} onChange={handleChangeProfile} value={profile?.checksenha || ""} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch color="secondary" name="alterarPodioCredencial" checked={profile?.alterarPodioCredencial || false} onChange={handlePodioCredentialChange} />}
                                        label={"Alterar credenciais do podio"}
                                    />
                                </FormGroup>
                            </Grid>
                            {profile?.alterarPodioCredencial && 
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextField label="Email do pódio" fullWidth name="podioLogin" onChange={handleChangeProfile} value={profile?.podioLogin || ""} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Senha do pódio" fullWidth name="podioSenha" onChange={handleChangeProfile} type="password" value={profile?.podioSenha || ""} />
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="secondary" onClick={handleSubmit}>Enviar</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="error" onClick={handleClose}>Cancelar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}