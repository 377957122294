import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { UserContext } from 'components/UserProvider/';
import LogoutIcon from '@mui/icons-material/Logout';
import Person from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Profile from './components/Profile';
import { getInitials } from 'Utils/';
const adminPages = ['WorkFlow', 'Logs', 'Help']
const pages = [ 'Power BI'];


export default function ResponsiveAppBar() {
  const [ anchorElNav, setAnchorElNav ] = React.useState(null);
  const [ anchorElUser, setAnchorElUser ] = React.useState(null);
  const [ modalProfileOpen, setModalProfileOpen ] = React.useState(false);
  const { logout, isAuthenticated, user } = React.useContext(UserContext)
  const navigate = useNavigate()
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenProfile = () => {
    setModalProfileOpen(true)
  }

  const handleCloseProfile = () => {
    setModalProfileOpen(false);
  }

  const handleNavigate = (event) => {
    handleCloseNavMenu();
    navigate(event.target.dataset['page'].replaceAll(" ", ""));
  }
  const settings = [
    {
      title: "Perfil",
      onClick: handleOpenProfile,
      icon: Person,
      admin: false
    },
    {
      title: "Usuários",
      onClick: () => navigate("/user"),
      icon: PeopleIcon,
      admin: true
    },
    {
      title: "Sair",
      onClick: logout,
      icon: LogoutIcon,
      admin: false
    }
  ];
  return (
    isAuthenticated &&
    <AppBar style={{position: "sticky", top: 0, zIndex: 2}}>
      <Profile open={modalProfileOpen} handleClose={handleCloseProfile} />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} color="secondary" />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#ffc90b',
              textDecoration: 'none',
            }}
          >
            NEO
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {user['admin'] == true && adminPages.map((page) => (
                <Button
                  key={page}
                  data-page={page}
                  onClick={handleNavigate}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
              {pages.map((page) => (
                <MenuItem key={page} data-page={page} onClick={handleNavigate}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} color="secondary" />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#ffc90b',
              textDecoration: 'none',
              
            }}
          >
            NEO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {user['admin'] == true && adminPages.map((page) => (
              <Button
                key={page}
                data-page={page}
                onClick={handleNavigate}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
            {pages.map((page) => (
              <Button
                key={page}
                data-page={page}
                onClick={handleNavigate}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Configurações">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={"profile"} src={user?.foto} >
                  {getInitials(user?.nome)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                (setting['admin'] == false || (setting['admin'] == true && user['admin'] == true)) &&
                  <MenuItem key={index} onClick={() => { handleCloseUserMenu(); setting.onClick() }}>
                    <ListItemIcon>
                      <setting.icon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{setting.title}</ListItemText>
                  </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}