export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export const getInitials = (name) => {
    let initials;
    if(!name) return;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};





export function filterTable(data, filter, columns) { // converte a query em uma string fuzzy
    if(filter === undefined)
        return data;
    return data.filter(item => {
        for (var column of columns) {
            var value = getPropertyByDotNotation(item, column.id)
            if (value && value.toString().toLowerCase().includes(filter.toLowerCase())) {
                // Se encontrar uma propriedade que corresponde ao termo de busca, retorna true
                return true;
            }
        }
        return false;
    });
}

export function getPropertyByDotNotation(object, propertyString) {
    const properties = propertyString.split('.');
    let currentProperty = object;

    for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        if (currentProperty.hasOwnProperty(property)) {
            currentProperty = currentProperty[property];
        } else {
            return undefined;
        }
    }

    return currentProperty;
}

export function resizeImage(image, newWidth, newHeight) {
    return new Promise((resolve) => {
        // Cria um objeto do tipo Canvas e define as dimensões da imagem redimensionada
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Cria um objeto do tipo Image e define a função a ser executada quando a imagem for carregada
        const img = new Image();
        img.onload = () => {
            // Obtém o contexto 2D do Canvas e desenha a imagem redimensionada nele
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Converte o Canvas de volta para o formato de imagem e retorna o resultado
            const dataUrl = canvas.toDataURL();
            resolve(dataUrl);
        };

        // Define a URL da imagem e inicia o carregamento
        img.src = image;
    });
}

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
}