import React from 'react';
import {  BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home'
import Login from './pages/Login';
import Logs from './pages/Logs'
import Help from './pages/Help'
import PowerBI from './pages/PowerBI'
import PowerBIViewer from 'pages/PowerBIViewer';
export default function Router(){
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route path='/' exact element={<PrivateRoute />}>
                    <Route path={"/"} exact element={<Navigate to={"/home"} />} />
                    <Route path={"/home"} exact element={<Home />} />
                    <Route path={"/workflow"} exact element={<Home />} />
                    <Route path={"/logs"} exact element={<Logs />} />
                    <Route path={"/help"} exact element={<Help />} />
                    <Route path={"/powerbi"} exact element={<PowerBI />} />
                    <Route path={"/powerbi/:id"} exact element={<PowerBIViewer />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}