import React from 'react'
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Addchart, Refresh } from '@mui/icons-material';
import { UserContext } from 'components/UserProvider';

export default function MenuContext({ anchorEl, onClose, onRefreshData, onAddPowerBI}) {
    const { user } = React.useContext(UserContext)
    const handleRefresh = () => {
        onRefreshData();
        onClose();
    };
    const handleAddPowerBI = () => {
        onAddPowerBI();
        onClose();
    };
    return (
        <>
            <Menu
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl !== null ? {
                        top: anchorEl.mouseY,
                        left: anchorEl.mouseX,
                    } : undefined
                }
            >
                <MenuItem onClick={handleRefresh}>
                    <ListItemIcon><Refresh /></ListItemIcon>
                    Atualizar dados
                </MenuItem>
                {user['admin'] == true && 
                    <MenuItem onClick={handleAddPowerBI}>
                        <ListItemIcon><Addchart /></ListItemIcon>
                        Adicionar Relatório
                    </MenuItem>
                }
            </Menu>
        </>
    );
}
