import React from 'react'
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { AddToQueue, Addchart, Refresh } from '@mui/icons-material';

export default function MenuContext({ anchorEl, onClose, onRefreshData, onAddTarefa }) {

    const handleRefresh = () => {
        onRefreshData();
        onClose();
    };
    const handleAddTarefa = () => {
        onAddTarefa();
        onClose();
    };
    return (
        <>
            <Menu
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl !== null ? {
                        top: anchorEl.mouseY,
                        left: anchorEl.mouseX,
                    } : undefined
                }
            >
                <MenuItem onClick={handleRefresh}>
                    <ListItemIcon><Refresh /></ListItemIcon>
                    Atualizar dados
                </MenuItem>
                <MenuItem onClick={handleAddTarefa}>
                    <ListItemIcon><AddToQueue /></ListItemIcon>
                    Adicionar Tarefa
                </MenuItem>
            </Menu>
        </>
    );
}
