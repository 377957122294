import React, { useState } from 'react';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import cronParser from 'cron-parser';
const CronPage = () => {
    const [cron, setCron] = useState('');
    const [nextExecutions, setNextExecutions] = useState([]);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };


    const handleCronChange = (event) => {
        setCron(event.target.value);
    };

    const calculateNextExecutions = () => {
        try {
            const interval = cronParser.parseExpression(cron);
            const nextDatesArray = [];

            for (let i = 0; i < 6; i++) {
                let data = new Date(interval.next());
                let dataFormatada = data.toLocaleDateString('pt-BR', options);
                nextDatesArray.push(dataFormatada);
            }

            setNextExecutions(nextDatesArray);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        calculateNextExecutions();
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" component="h1" gutterBottom>
                Como funciona uma expressão cron?
            </Typography>
            <Typography paragraph>
                Uma expressão cron é uma string que define um padrão para executar uma tarefa
                de forma recorrente em um sistema operacional baseado em Unix. A expressão é
                composta por seis campos que definem quando a tarefa deve ser executada:
                segundos, minutos, horas, dia do mês, mês e dia da semana.
            </Typography>
            <Typography paragraph>
                Cada campo é uma lista de valores separados por vírgula, ou um intervalo de
                valores definido por um hífen. Além disso, alguns caracteres especiais podem
                ser utilizados para representar valores como "todos" (*) ou "intervalos" (/).
                A seguir, é mostrado um exemplo de uma expressão cron:
            </Typography>
            <Typography variant="code" sx={{ bgcolor: 'background.default', p: 0 }}>
                <pre>
                    # ┌───────────── segundos (0 - 59) <br />
                    # │  ┌───────────── minutos (0 - 59)<br />
                    # │  │  ┌───────────── hora (0 - 23)<br />
                    # │  │  │  ┌───────────── dia do mês (1 - 31)<br />
                    # │  │  │  │  ┌───────────── mês (1 - 12)<br />
                    # │  │  │  │  │  ┌──────────── dia da semana (0 - 6)<br />
                    # │  │  │  │  │  │<br />
                    # │  │  │  │  │  │<br />
                    # *  *  *  *  *  * command to execute<br />
                    ' 0  0  0  *  *  *'  // Executa todos os dias à meia-noite<br />
                    ' 0  0  0  *  *  0,3'  // Executa no domingo (0) e na quarta feira (3) à meia-noite <br />
                    ' 0  0  18 *  *  3'  // Executa na quarta feira (3) as 18 horas <br />
                </pre>

                
            </Typography>
            <Typography paragraph>
                Para calcular as próximas 6 datas de execução da expressão cron, informe a
                expressão no campo abaixo e clique no botão "Calcular".
            </Typography>
            <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <TextField
                            label="Expressão cron"
                            
                            value={cron}
                            onChange={handleCronChange}
                            variant="outlined"
                        /> 
                    </Grid>
                    <Grid item>
                        <Button type="submit" color='secondary' variant='contained'>Calcular</Button>
                    </Grid>
                </Grid>
                
            </form>
            {nextExecutions.length > 0 && (
                <div>
                    <Typography variant="h6" gutterBottom>
                        Próximas 6 datas de execução:
                    </Typography>
                    <ul>
                        {nextExecutions.map((date) => (
                            <li key={date}>{date}</li>
                        ))}
                    </ul>
                </div>
            )}
        </Container>
    );
};
export default CronPage;