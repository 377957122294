import { Cancel, Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

const ConfirmDialog = createContext(null);

export const ConfirmDialogProvider = ({ children }) => {
    const [confirmation, setConfirmation] = useState(null);

    const openConfirmDialog = ({ message, title, onConfirm }) => {
        setConfirmation({ message, title, onConfirm });
    };

    const closeConfirmDialog = () => {
        setConfirmation(null);
    };
    const handleConfirmDialog = () => {
        confirmation.onConfirm();
        closeConfirmDialog();
    }
    return (
        <ConfirmDialog.Provider value={ openConfirmDialog }>
            {children}
            {confirmation && (
                <Dialog open onClose={closeConfirmDialog}>
                    <DialogTitle>{confirmation.title}</DialogTitle>
                    <DialogContent>{confirmation.message}</DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog} variant='outlined' color="secondary" endIcon={<Cancel />}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleConfirmDialog}
                            color="error"
                            autoFocus
                            variant='outlined'
                            endIcon={<Delete/>}
                        >
                            Excluir
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </ConfirmDialog.Provider>
    );
};

export const useConfirm = () => useContext(ConfirmDialog);
