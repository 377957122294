import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import darkScrollbar from '@mui/material/darkScrollbar';
import { UserProvider } from './components/UserProvider';
import Router from './routes';
import { ConfirmDialogProvider } from 'components/ConfirmDialog';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: "dark",
    secondary:  {
      main: "#ffc90b"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
      }),
    },
  },
});
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/> 
        <UserProvider>
        <ToastContainer
          position="top-right"
          style={{ marginTop: '4em' }}
        />
        <ConfirmDialogProvider>
          <Box id="loading" style={{ display: 'none' }}>
            <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: 'center' }}>
              <CircularProgress color='secondary' />
            </Box>
          </Box>
          <Router />
        </ConfirmDialogProvider>
        </UserProvider>
      
    </ThemeProvider>
  );
}

