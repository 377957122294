import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@mui/material';
import cronExpression  from 'cron-parser';
import { toast } from 'react-toastify'
import api from '../../../api';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TransitionsModal({open, onClose, currentEditing, onRefreshData}) {
    const [tarefa, setTarefa] = React.useState({ id: 0, nome: "", descricao: "", workFlowId: 0, cronExpression: "0 0 0 * * 0,3"});
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            cronExpression.parseExpression(tarefa.cronExpression)
        } catch(err) {
            toast.error("Expressão cron inválida, acesse a guia Help para obter informações de como usar o Cron!")
            return;
        }
        var res = null;
        if(tarefa['id'] == 0) {
            res = await api.post("tarefa", tarefa)
        } else {
            res = await api.put("tarefa", tarefa);
        }
        if (res['type'] === "success") {
            onRefreshData();
            onClose();
        }
    } 
    const handleChange = (event) => {
        setTarefa(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
    }
    React.useEffect(() => {
        if(currentEditing == null) {
            setTarefa({ id: 0, nome: "", descricao: "", workFlowId: 0, cronExpression: "0 0 0 * * 0,3" })
        }
        else {
            setTarefa(currentEditing)
        }
        
    },[currentEditing])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={onClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography id="transition-modal-title" variant="h6" textAlign={"center"} component="h2">
                                    {tarefa.id === 0 ? "Cadastrar" : "Editar"} Tarefa
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Nome" fullWidth name="nome" onChange={handleChange} value={tarefa.nome}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Descricao" fullWidth name="descricao" onChange={handleChange} value={tarefa.descricao} multiline rows={3}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="WorkFlowId" fullWidth name="workFlowId" onChange={handleChange} type={"number"} value={tarefa.workFlowId} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Expressão Cron" fullWidth name="cronExpression" onChange={handleChange} value={tarefa.cronExpression} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="secondary" onClick={handleSubmit}>Enviar</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="error" onClick={onClose}>Cancelar</Button>
                            </Grid>
                        </Grid> 
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}