import { Box } from '@mui/system';
import api from 'api';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
const PowerBIViewer = () => {
    const { id } = useParams()
    const [report, setReport] = React.useState({});
    useEffect(() => {
        (async () => {
            var res = await api.get(`powerbi/${id}`)
            if(res['type'] === 'success')
                setReport(res['data'])
        })()
    },[id])
    return (
        <Box sx={{height: 'calc(100% - 80px)', width: '100%'}}>
            <iframe
                title={report.title}
                width="100%"
                height="100%"
                src={report.link} frameBorder={0} allowFullScreen={true}
            />
        </Box>
    )
}

export default PowerBIViewer;
