import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { fileToBase64, resizeImage } from "../../../Utils";

const ProfileAvatar = ({value, onChange}) => {
    const [image, setImage] = React.useState("");
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        var base64 = await fileToBase64(selectedFile)
        const fakeEvent = {
            target: {
                value: base64,
                name: "foto"
            }
        }
        onChange(fakeEvent);
        // Aqui você pode fazer a chamada da API para salvar a nova imagem de perfil
    };

    const handleAvatarClick = () => {
        document.getElementById("avatar-selector").click();
    };
    
    React.useEffect(() => {
        resizeImage(value, 150, 150).then((imageResize) =>{
            setImage(imageResize)
        })        
    },[value])
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{ position: "relative", display: "inline-block" }}>
                <Avatar
                    alt="User Profile"
                    src={image}
                    sx={{ width: 150, height: 150, backgroundSize: "cover"}}
                    onMouseOver={(e) => (e.currentTarget.style.cursor = "pointer")}
                    onClick={handleAvatarClick}

                />
                <input
                    type="file"
                    accept="image/*"
                    id="avatar-selector"
                    name="foto"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <IconButton
                    sx={{ position: "absolute", bottom: 0, right: 0 }}
                    onMouseOver={(e) => (e.currentTarget.style.cursor = "pointer")}
                    onClick={handleAvatarClick}
                >
                    <PhotoCamera />
                </IconButton>
            </div>
        </div>
    );
};

export default ProfileAvatar;