import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NeoBg from '../../assets/neo.png'
import { UserContext } from '../../components/UserProvider';
import { Navigate, useNavigate } from 'react-router-dom';
import api from '../../api';
import { FormControlLabel, Paper, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
export default function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [keepLoggedIn, setKeepLoggedIn] = useState(true);
    const { login, isAuthenticated } = useContext(UserContext)
    const navigate = useNavigate()
    const handleLogin = async () => {
        let { type, data } = await api.post("session", { username, password, keepLoggedIn }); 

        if(type === "success") {
            localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data)
            login(true)
            navigate("/powerbi")
        }
        
    };
    return (
        isAuthenticated ?
        <Navigate to={"/powerbi"} /> 
        :
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: 380,
                    height: 450,
                },
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "25px"
            }}
        >
            <Paper sx={{borderRadius: "25px"}}>
                <div style={{height: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src={NeoBg} alt="neo" style={{width: "75%", height: "75%"}}/>
                </div>
                <div
                    style={{
                        width: "100%",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "center"
                    }}
                >
                    
                    <form 
                        style={{
                            width: "280px",
                            minWidth: "280px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "20px",
                            justifyContent: "center"
                        }}
                    >
                        <TextField
                            label="Usuário ou Email"
                            name="user"
                            autoComplete="outrora"
                            variant="standard"
                            value={username}
                            fullWidth
                            onChange={e => setUsername(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            size="medium"
                        />
                        <TextField
                            label="Senha"
                            autoComplete="password"
                            variant="standard"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            size="medium"
                        />
                        <FormControlLabel control={<Switch checked={keepLoggedIn} onChange={({ target: { checked } }) => setKeepLoggedIn(checked)} />} label="manter conectado" />
                        <Button variant="contained" color="primary" onClick={handleLogin}>
                            Login
                        </Button>
                    </form>
                
                </div>
                <div style={{height: "50px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography>
                        &copy; 2023 - {new Date().getFullYear()} NEO Engenharia de Produção
                    </Typography>
                </div>
            </Paper >
        </Box>
    );
}