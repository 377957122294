import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Button, Grid, Typography, TextField, TableSortLabel } from '@mui/material';
import api from 'api'
import { Container } from '@mui/system';
import { filterTable, getPropertyByDotNotation} from 'Utils/'
import ManageTarefa from './components/ManageTarefa'; 
import MenuContext from './components/MenuContext';
import { Delete, Edit } from '@mui/icons-material';
import { useConfirm } from 'components/ConfirmDialog';
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
const columns = [
    { id: 'nome', label: 'Nome', minWidth: 0 },
    { id: 'descricao', label: 'Descrição', minWidth: 100 },
    { id: 'workFlowId', label: 'WorkFlowId', minWidth: 170, align: 'left' },
    { id: 'cronExpression', label: 'Expressão Cron', minWidth: 170, align: 'left' },
    { id: 'usuario.nome', label: 'Usuário', minWidth: 170, align: 'left' },
    { id: 'proximaSincronizacao', label: 'Próxima Sincronização', minWidth: 170, align: 'left', format: (value) => value ? new Date(value).toLocaleDateString('pt-BR', options) : ""},
];

const Home = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = React.useState([]);
    const [ filter, setFilter ] = React.useState("");
    const [manageTaskOpen, setManageTaskOpen] = React.useState(false);
    const [currentEditing, setCurrentEditing] = React.useState(null);
    const [orderBy, setOrderBy] = useState('tarefa.nome');
    const [order, setOrder] = useState('asc');
    const [anchorEl, setAnchorEl] = useState(null);
    const confirmDialog = useConfirm()
    const handleAdd = () => {
        setCurrentEditing(null);
        handleOpenManageTask();
    }
    const handleEdit = (event) => {
        var id = event.target.dataset.id;
        if(id == null) return;
        var task = rows.filter(r => r['id'] == id)[0];
        if(task == null) return;
        setCurrentEditing(task)
        handleOpenManageTask();
    }

    const handleDelete = (event) => {
        var id = event.target.dataset.id;
        if (id == null) return;
        var task = rows.filter(r => r['id'] == id)[0];
        if (task == null) return;
        confirmDialog({
            message: `Você realmente deseja deletar a tarefa: ${task.nome}`,
            title: 'Deletar Tarefa',
            onConfirm: async () => {
                const res = await api.delete(`tarefa/${id}`);
                if (res.type === 'success') handleRefreshData();
            },
        })
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(
            anchorEl === null
                ? {
                    mouseX: event.clientX ,
                    mouseY: event.clientY,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };
    const handleCloseMenuContext = () => {
        setAnchorEl(null);
    };

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(columnId);
    };

    const handleCloseManageTask = () => {
        setManageTaskOpen(false)
    }

    const handleOpenManageTask = () => {
        setManageTaskOpen(true)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleFilter = (event) => {
        setFilter(event.target.value)
    }
    const handleRefreshData = React.useCallback(async () => {
        const res  = await api.get("tarefa")
        if(res['type'] === 'success')
            setRows(res['data']);
    },[])
    React.useEffect(() => {
        handleRefreshData();
    }, [handleRefreshData])


    return (
        <Container maxWidth="xl">
            <ManageTarefa open={manageTaskOpen} onClose={handleCloseManageTask} currentEditing={currentEditing} onRefreshData={handleRefreshData}/>
            <MenuContext anchorEl={anchorEl} onClose={handleCloseMenuContext} onRefreshData={handleRefreshData} onAddTarefa={handleAdd}/>
            <Grid container direction="column" gap={3} marginTop={3}>
                <Grid item container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" align="center">
                            Tarefas
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer sx={{ maxHeight: "calc(100vh - 240px)" }}>
                        <Table stickyHeader aria-label="sticky table" onContextMenu={handleContextMenu}>
                            <TableHead >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, background: "#444444" }}
                                            hidden={column.hidden}
                                            sortDirection={orderBy === column.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={() => handleSort(column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ background: "#444444", minWidth: 220 }} colSpan={2}>
                                        <TextField fullWidth label="Procurar..." value={filter} onChange={handleFilter} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterTable(rows, filter, columns)
                                    .sort((a, b) => {
                                        const aVal = getPropertyByDotNotation(a, orderBy);
                                        const bVal = getPropertyByDotNotation(b, orderBy);
                                        if (order === 'asc') {
                                            return aVal > bVal ? 1 : -1;
                                        } else {
                                            return aVal < bVal ? 1 : -1;
                                        }
                                    })
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = getPropertyByDotNotation(row, column.id)
                                                return (
                                                    <TableCell key={column.id} align={column.align} hidden={column.hidden}>
                                                        {column.format ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <Button color="secondary" variant="outlined" data-id={row['id']} endIcon={<Edit />} onClick={handleEdit}>Editar</Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button color="error" variant="outlined" data-id={row['id']} endIcon={<Delete />} onClick={handleDelete}>Deletar</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página:"
                    />
                </Grid>
            </Grid>            
        </Container>
        
    );
};

export default Home;