import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

var requests = 0;
const api = axios.create({
    baseURL: process.env.REACT_APP_API
});
api.interceptors.request.use((config) => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    config.headers.Authorization = `Bearer ${token}`;
    requests++;
    if (requests === 1) {
        document.getElementById('loading').style.display = 'block';
    }
    return config;
});
api.interceptors.response.use(
    (res) => {
        requests--;
        if (requests === 0) {
            document.getElementById('loading').style.display = 'none';
        }
        const { type, message, showToast } = res.data;
        if(showToast)
            toast(message, {
                type,
                duration: 3500,
                closeButton: true,
                position: "top-right"
            })
        return res.data
    },
    (err) => {
        requests--;
        if (requests === 0) {
            document.getElementById('loading').style.display = 'none';
        }
        const { code } = err
        if (code === "ERR_NETWORK") {
            toast.error("Verifique sua conexão com a internet!");
            return Promise.reject(err);
        }
        const { status } =  err?.response
        if(status === 401)  {
            localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME)
            return window.location = "login";
        }
            
        const { type, message, showToast } = err?.response?.data;
        if (showToast)
            toast(message, {
                type,
                duration: 3500,
                closeButton: true,
                position: "top-right"
            })
        return Promise.reject(err);
    }
)
export default api;